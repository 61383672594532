<template>
  <b-card>
    <div class="d-flex justify-content-between flex-wrap my-1">
      <h4>{{ $t("backoffice.settings.subtitle-phones") }}</h4>
      <div class="d-flex">
        <b-button variant="primary" @click="addPhoneCouple()">
          <feather-icon icon="PlusCircleIcon" class="text-white" />
        </b-button>
        <b-button variant="primary" class="ml-1" @click="savePhones()">
          {{ $t("form.actions.save") }}
        </b-button>
      </div>
    </div>
    <b-row v-if="isFirstPhoneSectionVisible">
      <b-col cols="12" lg="6">
        <!-- Name -->
        <b-form-group class="flex-grow-1">
          <label for="phone-name" class="font-weight-bold">
            {{ $t("backoffice.settings.name") }}</label
          >
          <b-form-input
            id="phone-name"
            v-model="currentPhone.label"
            type="text"
            :placeholder="$t('form.type-placeholder')"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <!-- Phone -->
        <b-form-group class="flex-grow-1">
          <span class="d-flex">
            <span>
              <label for="phone-prefix" class="font-weight-bold">
                {{ $t("backoffice.settings.prefix-label") }}
              </label>
              <v-select
                id="phone-prefix"
                v-model="currentPhone.country"
                :placeholder="$t('form.select-placeholder')"
                :options="prefixOptions"
                searchable
                :clearable="false"
                label="name"
                :get-option-label="
                  (a) => a.name[locale] + ' ' + '+' + a.phoneCode
                "
                style="width: auto; min-width: 220px; margin-right: 5px"
              />
            </span>
            <span class="flex-grow-1">
              <label for="phone-number" class="font-weight-bold">
                {{ $t("backoffice.settings.phone-label") }}</label
              >
              <b-form-input
                id="phone-number"
                v-model="currentPhone.number"
                type="text"
                :state="isValid"
                :placeholder="$t('form.type-placeholder')"
                @input="validator(currentPhone.number)"
              />
            </span>
          </span>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- All phones -->
    <b-row
      v-for="(phone, index) in phones"
      :key="index"
      class="d-flex flex-wrap"
    >
      <b-col cols="12" lg="6">
        <!-- Phone Label -->
        <b-form-group class="flex-grow-1">
          <label for="phone-name" class="font-weight-bold">
            {{ $t("backoffice.settings.name") }}</label
          >
          <b-form-input
            id="phone-name"
            v-model="phones[index].label"
            type="text"
            :placeholder="$t('form.type-placeholder')"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <!-- Phone prefix and number -->
        <b-form-group class="flex-grow-1">
          <span class="d-flex flex-wrap align-items-center">
            <!-- Phone prefix -->
            <span>
              <label for="phone-prefix" class="font-weight-bold">
                {{ $t("backoffice.settings.prefix-label") }}
              </label>
              <v-select
                id="phone-prefix"
                v-model="phones[index].country"
                :searchable="false"
                :placeholder="$t('form.select-placeholder')"
                :options="prefixOptions"
                :clearable="false"
                :get-option-label="
                  (a) => a.name[locale] + ' ' + '+' + a.phoneCode
                "
                label="name"
                style="width: auto; min-width: 220px; margin-right: 5px"
              />
            </span>
            <!-- Phone number -->
            <span class="flex-grow-1">
              <label for="phone-number" class="font-weight-bold">
                {{ $t("backoffice.settings.phone-label") }}
              </label>
              <b-form-input
                id="phone-number"
                v-model="phones[index].number"
                type="text"
                :placeholder="$t('form.type-placeholder')"
              />
            </span>
            <!-- Delete phone -->
            <feather-icon
              icon="Trash2Icon"
              size="16"
              class="ml-50 mt-2 text-primary pointer"
              @click="removeItem(index)"
            />
          </span>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { validatorPhoneValidator } from "@/@core/utils/validations/validators";

export default {
  name: "Phones",
  components: {
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      currentPhone: {
        label: null,
        phone: null,
        country: null,
      },
      phones: [],
      isValid: null,
      isValidPrefix: null,
      isFirstPhoneSectionVisible: false,
      prefixOptions: [],
      isRemoving: false,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isSaveEnabled() {
      return (
        this.form.name &&
        this.form.slug &&
        (this.form.accessibilityAux || this.form.accessibility) &&
        (this.form.visibilityAux || this.form.visibility)
      );
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return (
        !this.form.name ||
        !this.form.slug ||
        !this.form.visibility ||
        !this.form.accessibility
      );
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  created() {
    this.phones = this.currentCollective.phones;
    this.getPrefixes();
    if (this.phones?.length > 0) {
      this.isFirstPhoneSectionVisible = false;
    } else {
      this.isFirstPhoneSectionVisible = true;
    }
  },
  methods: {
    addPhoneCouple() {
      if(!this.phones){
        this.phones = [];
      }
      if (
        this.currentPhone.number &&
        this.currentPhone.number !== null &&
        validatorPhoneValidator(this.currentPhone.number)
      ) {
        this.phones.push(this.currentPhone);
        this.currentPhone = {};
        this.isValid = null;
        this.isValidPrefix = null;
      } else if (this.isRemoving) {
        this.isValid = null;
      } else {
        this.isValid = false;
      }
      this.isFirstPhoneSectionVisible = true;
    },
    async savePhones() {
      this.addPhoneCouple();
      this.phones = this.phones.filter(({ number }) => number !== "");
      this.phones?.length > 0 ? (this.isFirstPhoneSectionVisible = false) : true;
      this.currentPhone = {};
      const telephones = this.phones.map(({ number, label, country }) => ({
        number,
        label,
        extension: country?.phoneCode,
      }));
      // console.log('telephones', telephones);
      if (telephones?.length > 0 || this.isRemoving) {
        try {
          await this.$store.dispatch("addSettings", { phones: telephones });
          this.notifySuccess(this.$t("backoffice.settings.messages.success"));
        } catch {
          this.notifyError(this.$t("backoffice.settings.messages.error"));
        }
      } else {
        this.notifyError(this.$t("backoffice.settings.messages.error"));
      }
      this.isRemoving = false;
    },
    validator(item) {
      this.isValid = validatorPhoneValidator(item);
    },
    async getPrefixes() {
      const response = await this.$store.dispatch("getPrefixes");
      this.prefixOptions = response.data;
    },
    translateTranslationTable,
    removeItem(index) {
      this.phones.splice(index, 1);
      this.isRemoving = true;
      this.isValid = null;
      if (this.phones?.length <= 0) {
        this.addPhoneCouple();
      }
    },
  },
};
</script>
